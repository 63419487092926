import React from 'react';
import ThemeSwitchButton from '@components/common/ThemeSwitchButton';
import Header from '@layouts/Header';
import Footer from '@layouts/Footer';
import './style.scss';

const Layout = ({ children }) => {
  return (
    <div id="wrapper">
      <Header />
      <main>{children}</main>
      <Footer />
      <div className="theme-switch-button-wrapper">
        <ThemeSwitchButton />
      </div>
    </div>
  );
};

export default Layout;
