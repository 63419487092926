import { useLocation } from '@reach/router';

export const useLocationUtils = () => {
  const location = useLocation();

  const partiallyHasPathname = (pathname) => {
    return location.pathname.includes(pathname);
  };

  const partiallyHasHash = (hash) => {
    return location.hash.includes(hash);
  };

  return { partiallyHasPathname, partiallyHasHash };
};
