import { graphql, Link } from 'gatsby';
import React from 'react';
import CategoryList from '@components/Blog/CategoryList';
import PostDetail from '@components/Blog/PostDetail';
import TableOfContents from '@components/Blog/TableOfContents';
import Layout from '@layouts/Layout';
import './style.scss';
import Seo from '@components/common/Seo';

const BlogDetailTemplate = ({ data }) => {
  const {
    markdownRemark: {
      fields: { categories },
      frontmatter: { author, date, summary, title },
      html,
      tableOfContents,
    },
  } = data;

  return (
    <Layout>
      <section id="blog-detail">
        <div className="container">
          <div className="row">
            <div className="blog-detail">
              {/* category list */}
              <div className="category-list-wrapper">
                <CategoryList initialCategoryList={categories.filter((category) => category !== 'all')} />
              </div>

              {/* post detail */}
              <div className="post-detail-wrapper">
                <PostDetail author={author} date={date} summary={summary} title={title} html={html} />
              </div>

              {/* table of contents */}
              {tableOfContents && (
                <aside className="table-of-contents-wrapper">
                  <Link to="/blog"><button className="go-to-list-btn">목록으로</button></Link>
                  <TableOfContents contents={tableOfContents} />
                </aside>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default BlogDetailTemplate;

export const Head = ({ pageContext }) => {
  const { title, summary, keywords } = pageContext;
  return <Seo title={title} description={summary} keywords={keywords} />;
};

export const query = graphql`
  query ($id: String) {
    markdownRemark(id: { eq: $id }) {
      fields {
        categories
      }
      frontmatter {
        author
        date(formatString: "YYYY.MM.DD")
        summary
        title
      }
      html
      tableOfContents(maxDepth: 3)
    }
  }
`;
