import React, { useContext, useState } from 'react';
import { ThemeContext } from '@contexts/ThemeContext';
import { toggleDarkMode } from '@reducers/themeReducer';
import ImgDarkBtn from '@images/main/btn-dark.svg';
import ImgDarkBtnH from '@images/main/btn-dark-hover.svg';
import ImgLightBtn from '@images/main/btn-light.svg';
import ImgLightBtnH from '@images/main/btn-light-hover.svg';
import './style.scss';

const ThemeSwitchButton = () => {
  const { themeState, themeDispatch } = useContext(ThemeContext);
  const { isDarkMode } = themeState;

  const [isHovered, setIsHovered] = useState(false);

  const onToggleTheme = () => themeDispatch(toggleDarkMode());
  const onMouseEnterButton = () => setIsHovered(true);
  const onMouseLeaveButton = () => setIsHovered(false);

  return (
    <button
      className="theme-switch-button"
      onClick={onToggleTheme}
      onMouseEnter={onMouseEnterButton}
      onMouseLeave={onMouseLeaveButton}
    >
      {isDarkMode ? (
        <img className="theme-icon" src={isHovered ? ImgLightBtnH : ImgLightBtn} alt="light theme icon" />
      ) : (
        <img className="theme-icon" src={isHovered ? ImgDarkBtnH : ImgDarkBtn} alt="dark theme icon" />
      )}
    </button>
  );
};

export default ThemeSwitchButton;
