import React, { useContext } from 'react';
import { ThemeContext } from '@contexts/ThemeContext';
import './style.scss';

const PostDetail = ({ author, date, summary, title, html }) => {
  const { themeState } = useContext(ThemeContext);
  const { isDarkMode } = themeState;

  return (
    <div className="post-detail">
      <h1 className="post-title">{title}</h1>
      <p className="post-summary">{summary}</p>
      <div className="post-metadata">
        <time date={date}>{date}</time>
        <span>{author}</span>
      </div>
      <div className={isDarkMode ? "post-contents dark" : "post-contents"} dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};

export default PostDetail;
