import { Link } from 'gatsby';
import React from 'react';
import { useCategoryList } from '@hooks/useCategoryList';
import './style.scss';

const CategoryList = ({ initialCategoryList }) => {
  const { categoryList } = useCategoryList(initialCategoryList);

  const getActiveClassName = ({ href, isPartiallyCurrent, location }) => {
    if (href === '/blog') {
      if (location.pathname === '/blog' || location.pathname.includes('all')) {
        return { className: 'active' };
      }
    } else if (isPartiallyCurrent) {
      return { className: 'active' };
    } else {
      return {};
    }
  };

  return (
    <ul className="category-list">
      {categoryList.map((category) => (
        <li key={category}>
          <Link to={category === 'all' ? `/blog` : `/blog/${category}`} getProps={getActiveClassName}>
            {category}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default CategoryList;
