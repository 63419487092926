import { graphql, useStaticQuery } from 'gatsby';

export const useCategoryList = (initialCategoryList) => {
  const result = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        categories: distinct(field: fields___categories)
      }
    }
  `);

  return { categoryList: initialCategoryList || result.allMarkdownRemark.categories };
};
